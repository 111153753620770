<template>
	<main>
		<section class="service-area pb-70 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center mb-40">
						<div class="section-title service-title">
							<h2>Our Products</h2>
							<p>Products that you will love !!!</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-4 col-md-6 text-center mb-25">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/truck.svg" alt="FreightMan" />
							</div>

							<h4>FreightMan</h4>
							<div class="product_wrap pb-30">
								<p>
									An accounting and vehicle maintenance software for Truck
									Owners and Transport Contractors.
								</p>
								<p>
									Easy to use multi-user software helps you track each trip and
									generate invoices for them.
								</p>
								<p>
									Get reminders for critical jobs like paying for insurance
									premiums, submitting yearly and half yearly taxes, renewal of
									national / temporary permit or whatever you think requires to
									be reminded of…
								</p>
							</div>
							<div class="pt-20">
								<a href="/freightman" class="btn explore-btn">Explore</a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/gasoline-pump.svg" alt="TRADEBAZIX" />
							</div>

							<h4>TRADEBAZIX</h4>
							<div class="product_wrap pb-65">
								<p>
									Accounting and inventory software for Wholesalers and
									Retailers.
								</p>
								<p>
									Customizable for various industrial verticals such as Petrol
									Pumps, Shopping Malls, Wholesalers and Retailers.
								</p>
								<p>
									An easy to go user interface with automated inventory /
									accounts tracking.
								</p>
								<p>
									Can be customized for barcoding for easier inventory tracking
									and using it for POS.
								</p>
							</div>
							<a href="/tradebazix" class="btn explore-btn">Explore</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/ship.svg" alt="EXIMAX" />
							</div>

							<h4>EXIMAX</h4>
							<div class="product_wrap">
								<p>A complete accounting software for Custom House Agents.</p>
								<p>
									Generate shipping jobs for domestic, import and export
									consignments.Option to save relevant documents for future use.
								</p>
								<p>
									Automated documentation, invoicing and various report
									generation.
								</p>
								<p>
									Option to migrate accounting data to tally accounting
									software.
								</p>
								<p>Multi-user, with customizable user access levels.</p>
							</div>
							<div class="pt-20">
								<a href="/eximax" class="btn explore-btn">Explore</a>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/fast-food.svg" alt="FOODNOTE" />
							</div>

							<h4>FOODNOTE</h4>
							<div class="product_wrap pb-110">
								<p>A restaurant management software</p>
								<p>
									Manage table wise sales, menu item generation, menu item
									grouping, KOT generation and much more.
								</p>
								<p>
									Get in depth reports for menu group wise sales, menu item wise
									sales, day’s collection, days sales, sales segregated by
									Dine-In, Home-Delivery, Room-Service and Banquet Hall.
								</p>
							</div>
							<a href="/foodnote" class="btn explore-btn">Explore</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/water-truck.svg" alt="Cargo Manager" />
							</div>

							<h4>Cargo Manager</h4>
							<div class="product_wrap">
								<p>
									An inventory management software for liquid cargo terminals.
								</p>
								<p>Allows you to generate shipments for incoming cargo.</p>
								<p>Receive cargo by road, pipeline or rail.</p>
								<p>
									Create, manage and track Bill of Entry as well as Delivery
									Orders for cargo being cleared by customs.
								</p>
								<p>
									Allocate transporters for outward transportation of cargo and
									generate gate pass based on weighment received from
									weighbridge.
								</p>
							</div>
							<a href="/cargomanager" class="btn explore-btn">Explore</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/building.svg" alt="BizBank" />
							</div>

							<h4>BizBank</h4>
							<div class="product_wrap pb-135">
								<p>An ERP Software for manufacturers.</p>
								<p>
									Manage procurements, Production, Distribution and Accounting
									all in a single integrated software system.
								</p>
								<p>
									Track business resources cash, raw materials, production
									capacity and the status of business commitments: orders,
									purchase orders, and payroll
								</p>
							</div>
							<a href="/bizbank" class="btn explore-btn">Explore</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img
									src="assets/icons/money-graph-with-up-arrow.svg"
									alt="BizBank"
								/>
							</div>

							<h4>Stock Outlook</h4>
							<div class="product_wrap pb-100">
								<p>
									A complete inventory software for manufacturers and
									warehouses.
								</p>
								<p>
									Track stock items at various inventory levels. Place orders
									and track them easily.
								</p>
								<p>
									Compare rates from various suppliers and automate order
									placements.
								</p>
								<p>
									Set reminders for individual stock levels and get reminded
									upon their occurrences
								</p>
							</div>
							<a href="/stockoutlook" class="btn explore-btn">Explore</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/deforestation.svg" alt="BizBank" />
							</div>

							<h4>LogMagix</h4>
							<div class="product_wrap pb-90">
								<p>
									A complete software for timber importers and saw mill owners.
								</p>
								<p>
									Manage inventories and production, compare log consumption
									with sawn production as well as delivered timber logs with
									received timber logs.
								</p>
								<p>
									Option to generate various reports based on consignments,
									species, origin and manufactured products.
								</p>
							</div>
							<a href="/logmagix" class="btn explore-btn">Explore</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/accounting.svg" alt="BizBank" />
							</div>

							<h4>Scheme Manager</h4>
							<div class="product_wrap">
								<p>
									Accounting and receipt printing software for Land Developers
									and Scheme Organizers.
								</p>
								<p>
									Complete accounting with option to migrate all accounting
									entries to tally accounting software.
								</p>
								<p>
									Option to notify members for their receipt of instalments
									through SMS.
								</p>
								<p>
									Various reports including receipt / payment register, Payment
									due reminders, Accounting ledgers and balance sheet, trial
									balance and many more …
								</p>
							</div>
							<a href="/schememanager" class="btn explore-btn">Explore</a>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/cargo-boat.svg" alt="BizBank" />
							</div>

							<h4>Visual Shipment</h4>
							<div class="product_wrap">
								<p>
									Export shipment management software for clearing and
									forwarding agents.
								</p>
								<p>
									Create, manage and transfer shipments from one shipper to
									another or one shipment to another.
								</p>
								<p>Receive and verify cargo for damage or shortage</p>
								<p>
									Manage inventories inside container freight station
									categorized by shipper, shipment, commodity, vessel, cfs, port
									of discharge, country of destination and shipping line.
								</p>
								<p>
									Perform stuffing job request, stuffing and movement job
									request for received cargo.
								</p>
							</div>
							<a href="/visualshipment" class="btn explore-btn">Explore</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
	name: "products",
};
</script>

<style></style>
